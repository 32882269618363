import preventReClick from './addButton/preventReClick'

const install = function (Vue) {
  Vue.directive('preventReClick', preventReClick)
}

if (window.Vue) {
  Vue.use(install) // eslint-disable-line
}

export default install
