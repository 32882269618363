<template>
  <div class="message-tip-box" v-if="visibleMessage">
    <div class="message-tip">
      <div class="message-tip-title">{{ title }}</div>
      <div class="message-tip-content">
        {{ content }}
      </div>
      <div class="message-tip-footer" v-if="visibleCancle">
        <button @click="onConfirm" class="message-tip-footer-confirm">{{ confirmValue }}</button>
        <button @click="onCancle" class="message-tip-footer-cancle">{{ cancleValue }}</button>
      </div>
      <div class="message-tip-single" v-else>
        <button @click="onConfirm" class="message-tip-single-button">{{ confirmValue }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageTip',
  props: {
    title: {
      type: String,
      default: '提示'
    },
    content: {
      type: String,
      default: '我是内容'
    },
    visibleCancle: {
      type: Boolean,
      default: true
    },
    confirmValue: {
      type: String,
      default: '确定'
    },
    cancleValue: {
      type: String,
      default: '取消'
    },
    visibleMessage: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onConfirm() {
      this.$emit('onConfirm')
      this.remove() //移除
    },
    onCancle() {
      this.$emit('onCancle')
      this.remove() //移除
    }
  }
}
</script>

<style lang="scss" scoped>
.message-tip-box {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  .message-tip {
    width: 86%;
    height: 215px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 24px 31px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 20px;
      color: #373c41;
      font-weight: 500;
    }
    &-content {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #373c41;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &-footer {
      display: flex;
      margin: 0 auto;
      &-confirm {
        width: 130px;
        height: 40px;
        background: #ff5500;
        border-radius: 23px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
      &-cancle {
        width: 130px;
        height: 40px;
        border-radius: 23px;
        border: 1px solid #8e8e93;
        font-size: 16px;
        font-weight: 500;
        color: #373c41;
        margin-left: 24px;
      }
    }
    &-single {
      display: flex;
      justify-content: center;
      &-button {
        width: 80%;
        height: 40px;
        background: #ff5500;
        border-radius: 23px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
@media (min-width: 768px) {
  .message-tip{
    width:28% !important;
    min-width: 500px !important;
  }
}
</style>
