export default {
  state: {
    equipmentType: '' //设备类型
  },
  mutations: {
    set_equipmentType(state, equipmentType) {
      state.equipmentType = equipmentType
    }
  },
  actions: {
    SET_EQUIPMENTTYPE({ commit }, equipmentType) {
      commit('set_equipmentType', equipmentType)
    }
  }
}
