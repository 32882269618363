import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    // redirect: '/storeHome',
    component: Layout,
    children: [
      {
        // path: '/storeHome',
        path: '/',
        name: 'Home',
        component: resolve => require(['@/views/home'], resolve),
        meta: {
          visibleNavBar: true,
          visibleNavBarWeb: true,
          visibleFooter: true,
          visibleFooterWeb: true
        }
      }
    ]
  },
  {
    path: '/loginStore',
    component: resolve => require(['@/views/login'], resolve),
    name: 'Login',
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/confirmOrder',
        name: 'ConfirmOrder',
        component: resolve => require(['@/views/confirmOrder'], resolve),
        meta: {
          visibleNavBar: false,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true,
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/courseDetails',
        name: 'CourseDetails',
        component: resolve => require(['@/views/courseDetails'], resolve),
        meta: {
          visibleNavBar: true,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/paySucceed',
        name: 'PaySucceed',
        component: resolve => require(['@/views/paySucceed'], resolve),
        meta: {
          visibleNavBar: false,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true,
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/personalCenter',
        name: 'personalCenter',
        component: resolve => require(['@/views/personalCenter'], resolve),
        meta: {
          visibleNavBar: true,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true,
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/orderDetail',
        name: 'orderDetail',
        component: resolve => require(['@/views/personalCenter/personalContent/Order/orderDetail'], resolve),
        meta: {
          visibleNavBar: false,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true,
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/webPay',
        name: 'webPay',
        component: resolve => require(['@/views/webPay'], resolve),
        meta: {
          visibleNavBar: false,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true,
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/courseGroupDetail',
        name: 'courseGroupDetail',
        component: resolve => require(['@/views/courseGroupDetail'], resolve),
        meta: {
          visibleNavBar: false,
          visibleNavBarWeb: true,
          visibleFooter: false,
          visibleFooterWeb: true,
          requireAuth: false
        }
      }
    ]
  }
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  // base: '/store/',
  routes
})

export default router
