<template>
  <div class="affirm-dialog" v-show="affirmDialogShow">
    <div class="dialog">
      <div class="title">提示</div>
      <div class="content">
        <slot></slot>
      </div>
      <div class="btn">
        <div @click="cancelDialog">
          <slot name="left">取消</slot>
        </div>
        <div @click="submitDialog">
          <slot name="right">确认</slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AffirmDialog',
  props: {
    affirmDialogShow: {
      type: Boolean,
      default: false
    }
    // contentText: {
    //   type: String,
    //   default: '提示语'
    // }
  },
  data() {
    return {}
  },
  methods: {
    submitDialog() {
      this.$emit('submitDialog')
    },
    cancelDialog() {
      this.$emit('cancelDialog')
    }
  }
}
</script>
<style lang="scss" scoped>
.affirm-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.64);
  z-index: 999;
  .dialog {
    padding-top: 20px;
    margin: -100px auto 0;
    width: 90%;
    background: #fff;
    border-radius: 16px;
    position: relative;
    top: 50%;
    .title {
      text-align: center;
    }
    .content {
      text-align: center;
      font-size: 14px;
      padding: 0 30px;
      margin: 10px 0 25px;
      color: rgb(110, 111, 112);
    }
    .btn {
      display: flex;
      div {
        width: 50%;
        text-align: center;
        height: 46px;
        line-height: 46px;
        border-top: 1px solid #eee;
        cursor: pointer;
      }
      div:last-child {
        border-left: 1px solid #eee;
        color: rgb(238, 11, 37);
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .affirm-dialog {
    .dialog {
      width: 20%;
    }
  }
}
</style>
