export function getPublishBaseUrl() {
  return process.env.VUE_APP_BASE_STORE
}
// 后台管理系统
export function getBackstageUrl() {
  return process.env.VUE_APP_BASE_BACKSTAGE
}

// WEB端下载页面的跳转地址
export function getWebDownloadUrl() {
  return process.env.VUE_APP_BASE_WEBDOWNLOAD
}
