<template>
  <div class="to-loading">
    <div v-if="loadingShow" class="loading">
      <div class="icon"></div>
      <div class="text">加载中...</div>
    </div>
    <div v-if="noLoadingTextShow && !loadingShow" class="na-data">哎呀~ 到底了</div>
  </div>
</template>
<script>
export default {
  name: 'ToLoading',
  props: {
    loadingShow: {
      type: Boolean,
      default: false
    },
    noLoadingTextShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.to-loading {
  width: 100%;
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid #ff5500;
      border-left: 2px solid transparent;
      animation: loading 1s linear infinite;
    }
    @keyframes loading {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .text {
      font-size: 12px;
      color: #ff5500;
    }
  }
  .na-data {
    text-align: center;
    font-size: 12px;
    color: #ff5500;
  }
}
@media (min-width: 768px) {
  .to-loading {
    .loading {
      padding-top: 60px;
      .icon {
        width: 50px;
        height: 50px;
      }
      .text {
        font-size: 16px;
        margin-top: 10px;
        padding-bottom: 100px;
      }
    }
  }
}
</style>
