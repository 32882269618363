<template>
  <div class="alert-msg" v-if="visibleMsg" ref="alertMsg">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AlertMsg',
  props: {
    time: {
      type: Number,
      default: null
    },
    visibleMsg: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visibleMsg: {
      handler: function (newVal) {
        if (newVal) {
          if (this.interval) {
            clearTimeout(this.interval)
          }
          this.interval = setTimeout(() => {
            this.closeMsg()
          }, this.time)
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      interval: null
    }
  },
  methods: {
    closeMsg() {
      this.$emit('update:visibleMsg', false)
    },
    otherCloseMsg(e) {
      if (this.$refs.alertMsg && !this.$refs.alertMsg.contains(e.target)) {
        this.closeMsg()
      }
    }
  },
  mounted() {
    window.addEventListener('click', this.otherCloseMsg)
  },
  destroyed() {
    window.removeEventListener('click', this.otherCloseMsg)
  }
}
</script>

<style lang="scss" scoped>
.alert-msg {
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgb(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  z-index: 998;
}
</style>
