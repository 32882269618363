import router from './router'
import { getToken, setToken } from './utils/auth'

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  // 兼容IE
  window.scrollTo(0, 0)
  let token = getToken()
  // 1.解决后台管理系统和商城拆成2个域名后，从后台支付页面不能直接跳转到商城页面的问题
  // 2.解决从后台点击跳转商城 直接去登录页面的问题，跳转过来需要携带用户信息
  let tokenBackstage = to.query.token
  if (tokenBackstage) {
    setToken(tokenBackstage)
  }
  if (to.meta.requireAuth) {
    if (token || tokenBackstage) {
      //  当前的token是否存在
      next()
    } else {
      next({
        path: '/loginStore',
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})
