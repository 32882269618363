<template>
  <div class="no-data">
    <img src="../../assets/no-data.png" alt="" />
    <p class="no-data-text">
      <slot name="text"></slot>
      <span @click="goPage">
        <slot name="textAct"></slot>
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'NoData',
  props: {},
  data() {
    return {}
  },
  methods: {
    goPage(){
        this.$emit('goPage')
    }
  }
}
</script>
<style lang="scss" scoped>
.no-data {
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 233px;
    height: 131px;
    margin-bottom: 12px;
  }
  .no-data-text {
    font-size: 14px;
    font-weight: 400;
    color: #8e8e93;
    span {
      color: $robin-essential-color;
    }
  }
}
@media (max-width: 767px) {
  .no-data {
    padding: 100px 0;
    .no-data-text {
      font-size: 12px;
    }
  }
}
</style>