<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getToken, getStoreToken, setStoreToken } from '@/utils/auth'
import { setUserInfo, getUserInfo, setPermissions, getPermissions } from '@/utils/user'
import { getInfo } from '@/api/login'
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      screenWidth: null
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 768) {
          this.set_equipmentType('APP')
        } else {
          this.set_equipmentType('WEB')
        }
      },
      immediate: true
    }
  },
  created() {
    if (getToken()) {
      console.log('APP---created-getInfo')
      this.getInfo() // 解决从后台管理系统某些页面跳转时，得不到用户信息的问题
    }
    // this.$router.beforeEach((to, from, next) => {
    //   console.log('1111', to, from)
    //   console.log('变了吗', getToken() != getStoreToken())
    //   let isUserId =
    //     getUserInfo() != undefined && Cookies.get('Admin-UserId') != undefined
    //       ? JSON.parse(getUserInfo()).userId != Cookies.get('Admin-UserId')
    //       : true
    //   // console.log("🚀 ~ isUserId:", isUserId, JSON.parse(getUserInfo()).userId, '----', Cookies.get('Admin-UserId'))
    //   if (getToken() != undefined && getStoreToken() != undefined && getToken() != getStoreToken() && isUserId) {
    //     if (from.path == '/loginStore' || from.query.isJupm) {
    //       next()
    //     } else {
    //       this.$alert('用户信息已变更，请获取最新用户数据！', '系统提示', {
    //         confirmButtonText: '确定',
    //         callback: action => {
    //           this.getInfo()
    //           next()
    //         }
    //       })
    //     }
    //   } else if (getToken() != undefined && getStoreToken() == undefined) {
    //     this.getInfo()
    //     next()
    //   } else {
    //     next()
    //   }
    // })
    if (getPermissions() != undefined) {
      this.SET_PERMISSIONS(JSON.parse(getPermissions()))
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  methods: {
    ...mapActions({
      set_equipmentType: 'SET_EQUIPMENTTYPE',
      SET_USERINFO: 'SET_USERINFO',
      SET_PERMISSIONS: 'SET_PERMISSIONS'
    }),
    // 获取用户详情
    getInfo() {
      getInfo()
        .then(res => {
          let user = res.data.user
          let permissionFlag = ['shopping:record:pay', 'order:list:pay', 'shopping:businessVoucher:list']
          let permissions = res.data.permissions.some(permission => {
            return '*:*:*' === permission || permissionFlag.includes(permission)
          })
          let { userName, companyName, userId } = res.data.user
          let avatar = user.avatar ?? require('@/assets/no-avatar.png')
          let userInfo = {
            name: userName,
            avatar: avatar,
            companyName,
            userId
          }
          this.SET_USERINFO(userInfo)
          this.SET_PERMISSIONS(permissions)
          setUserInfo(JSON.stringify(userInfo))
          setPermissions(JSON.stringify(permissions))
          setStoreToken(getToken())
        })
        .catch(err => {})
    }
  }
}
</script>
