<template>
  <div class="footer-box">
    <div class="footer-content">
      <div class="footer-content-left">
        <div class="logo-name">企云巅</div>
        <div class="logo-subtitle">让每一天都有成长</div>
      </div>
      <div class="content-information">
        <div class="information-text">
          <!-- <div class="call-me">联系我们</div>
          <div class="information-email">邮箱：kefu@huizhiyunping.com</div>
          <div class="information-adress">地址：山东省青岛市城阳区汇智桥路中科青岛研发城</div> -->
          <div class="call-me"></div>
          <div class="information-email"></div>
          <div class="information-adress"></div>
        </div>
      </div>
      <div class="code-box">
        <div class="code">
          <QrCode :id="'QrCode'" :text="codeUrl" />
        </div>
        <span>扫码下载企云巅APP</span>
      </div>
    </div>
    <div class="copy-right">
      <span>Copyright © 2012-2024 山东汇智云屏网络科技有限公司 鲁ICP备2022005491号-2 v{{ version }}</span>
    </div>
  </div>
</template>

<script>
import { getWebDownloadUrl } from '@/utils/config.js'
export default {
  name: 'Footer',
  data() {
    return {
      codeUrl: null,
      version: process.env.VUE_APP_VERSION
    }
  },
  created() {
    this.$nextTick(() => {
      this.codeUrl = `${getWebDownloadUrl()}/download?type=screen`
    })
  }
}
</script>

<style lang="scss" scoped>
.footer-box {
  box-sizing: border-box;
  width: 100%;
  height: 370px;
  background: #fcfbfc;
  padding: 60px 20px 71px 30px;
  .footer-content {
    width: 100% !important;
    max-width: 1200px;
    margin: auto;
    display: flex;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20%;

      .logo-name {
        font-weight: 600;
        font-size: 24px;
        color: $robin-subject-font-color;
      }
      .logo-subtitle {
        font-weight: 400;
        font-size: 18px;
        color: #333333;
      }
    }
    .content-information {
      width: 60%;
      height: 100%;
      .information-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .call-me {
          font-weight: 600;
          font-size: 18px;
          color: #333333;
        }
        .information-email {
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          margin-top: 39px;
        }
        .information-adress {
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }
      }
    }
    .code-box {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .code {
        width: 87px;
        height: 87px;
      }
      span {
        margin-top: 12px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .copy-right {
    max-width: 1200px;
    margin: auto;
    font-weight: 400;
    font-size: 12px;
    color: #b8b8b8;
    margin-top: 109px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  // pc
}
</style>
