import Cookies from 'js-cookie'

const userInfo = 'Store-UserInfo'
export function getUserInfo() {
  return Cookies.get(userInfo)
}

export function setUserInfo(value) {
  return Cookies.set(userInfo, value)
}

export function removeUserInfo() {
  return Cookies.remove(userInfo)
}

const loginSuc = 'Store-LoginSuc'
export function getloginSuc() {
  return Cookies.get(loginSuc)
}

export function setloginSuc(value) {
  return Cookies.set(loginSuc, value)
}

export function removeloginSuc() {
  return Cookies.remove(loginSuc)
}

const userType = 'Store-UserType'
export function getUserType() {
  return Cookies.get(userType)
}

export function setUserType(value) {
  return Cookies.set(userType, value)
}

export function removeUserType() {
  return Cookies.remove(userType)
}

const permissions = 'Store-Permissions'
export function getPermissions() {
  return Cookies.get(permissions)
}

export function setPermissions(value) {
  return Cookies.set(permissions, value)
}

export function removePermissions() {
  return Cookies.remove(permissions)
}
