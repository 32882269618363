// 防止按钮多次点击
// 自定义一个指令 防止重复点击
export default {
  inserted(button, binding) {
    button.addEventListener('click', () => {
      if (!button.disabled) {
        button.disabled = true
        button.style.cursor = 'not-allowed'
        clearTimeout(button.timeId)
        button.timeId = setTimeout(() => {
          button.disabled = false
          button.style.cursor = 'default'
        }, binding.value || 1000)
      }
    })
  }
}
