<template>
  <div class="web-main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'WebMain'
}
</script>
<style scoped lang="scss">
.web-main {
  min-height: 100%;
  min-height: calc(100vh - 60px);
}
</style>
