import Cookies from 'js-cookie'

// const TokenKey = 'Admin-Token'
const TokenKey = 'Store-Token-Web'
//Store-Token

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const TokenStoreKey = 'Store-Token'
//Store-Token

export function getStoreToken() {
  return Cookies.get(TokenStoreKey)
}

export function setStoreToken(token) {
  return Cookies.set(TokenStoreKey, token)
}

export function removeStoreToken() {
  return Cookies.remove(TokenStoreKey)
}
