export default {
  state: {
    payInformation: {} // 支付状态相关
  },
  mutations: {
    set_payInformation(state, payInformation) {
      state.payInformation = payInformation
    }
  },
  actions: {
    SET_PAYINFORMATION({ commit }, payInformation) {
      commit('set_payInformation', payInformation)
    }
  }
}
