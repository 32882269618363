<template>
  <div class="input-number">
    <button type="button" class="down" @click="down">-</button>
    <input type="number" :value="value" class="input" @input="onHandleInput" @blur="onBlurInput" />
    <button type="button" class="up" @click="up">+</button>
  </div>
</template>

<script>
export default {
  name: 'InputNumber',
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    }
  },
  data() {
    return {
      currentInputValue: this.value,
      currentMin: this.min,
      currentMax: this.max
    }
  },
  methods: {
    onHandleInput(e) {
      let value = e.target.value
      let emitValue = 1
      // 防止输入不合法
      emitValue = parseInt(value)
      if (emitValue > this.currentMax) {
        emitValue = this.currentMax
        document.getElementsByTagName('input')[0].value = this.max
      }

      if (emitValue < this.currentMin) {
        emitValue = parseInt(this.currentMin)
      }

      this.currentInputValue = emitValue
      this.$emit('changeNum', parseInt(this.currentInputValue))
    },
    onBlurInput(e) {
      let value = e.target.value
      if (value == '') {
        this.currentInputValue = this.currentMin
        this.$emit('changeNum', parseInt(this.currentInputValue))
      }
    },
    // 减
    down() {
      if (this.currentMin != undefined) {
        if (parseInt(this.currentInputValue - 1) >= this.currentMin) {
          this.currentInputValue = this.currentInputValue - 1
          this.$emit('changeNum', parseInt(this.currentInputValue))
        }
      } else {
        this.currentInputValue = this.currentInputValue - 1
        this.$emit('changeNum', parseInt(this.currentInputValue))
      }
    },
    // 加
    up() {
      if (this.currentMax != undefined) {
        if (parseInt(this.currentInputValue + 1) <= this.currentMax) {
          this.currentInputValue = this.currentInputValue + 1
          this.$emit('changeNum', parseInt(this.currentInputValue))
        }
      } else {
        this.currentInputValue = this.currentInputValue + 1
        this.$emit('changeNum', parseInt(this.currentInputValue))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-number {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid $robin-sub-title-color;
  border-radius: 2px;
  .input {
    width: 45px;
    height: 100%;
    text-align: center;
    &:focus {
      outline: 1px solid transparent;
    }
  }
  .down {
    width: calc((100% - 45px) / 2);
    border-right: 0.5px solid #979797;
    color: #cacdd1;
  }
  .up {
    width: calc((100% - 45px) / 2);
    border-left: 0.5px solid #979797;
    color: #cacdd1;
  }
}
</style>
