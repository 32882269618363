<template>
  <div class="lay-out">
    <navbar v-if="(meta.visibleNavBar && equipmentType == 'APP') || (meta.visibleNavBarWeb && equipmentType == 'WEB')"></navbar>
    <web-main></web-main>
    <footer-content v-if="(meta.visibleFooter && equipmentType == 'APP') || (meta.visibleFooterWeb && equipmentType == 'WEB')"></footer-content>
  </div>
</template>

<script>
import Navbar from './NavBar'
import WebMain from './WebMain'
import FooterContent from './Footer'
import { mapGetters } from 'vuex'
export default {
  name: 'LayOut',
  components: {
    Navbar,
    WebMain,
    FooterContent
  },
  computed: {
    ...mapGetters(['equipmentType']),
    meta() {
      const route = this.$route
      const { meta } = route
      return meta
    }
  }
}
</script>
<style scoped lang="scss">
.lay-out {
  min-height: 100%;
}
</style>
