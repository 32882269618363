<template>
  <div class="tag">
    <div class="tag-content tail-tag-content tail-flex">
      <div class="tag-item-box tail-flex">
        <div
          class="tag-item"
          :class="activeIndex == index ? 'tag-item-active' : ''"
          v-for="(item, index) in tagListCopy"
          :key="item.id"
          @click="setActive(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="more tail-flex" v-if="tagList.length > 22">
        <div @click="getMore">{{ isShow ? '收起' : '展开' }}</div>
        <img :class="isShow ? 'img-active' : ''" src="../../assets/bottom.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TagBox',
  props: {
    tagList: {
      type: Array,
      default() {
        return [
          {
            label: 'tab1',
            id: '1'
          },
          {
            label: 'tab2',
            id: '2'
          },
          {
            label: 'tab3',
            id: '3'
          },
          {
            label: 'tab4',
            id: '4'
          },
          {
            label: 'tab5',
            id: '5'
          }
        ]
      }
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  watch: {
    tagList: {
      handler: function (newVal, oldVal) {
        if (!this.isShow) {
          this.tagListCopy = this.tagList.length > 22 ? this.tagList.slice(0, 22) : this.tagList
        } else {
          this.tagListCopy = this.tagList
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      tagListCopy: [],
      isShow: false
    }
  },
  methods: {
    setActive(value) {
      const { index, id } = value
      // 自动滚动到视口中
      this.$nextTick(() => {
        const activeElement = this.$refs[`tagItem${index}`]
        if (activeElement) {
          activeElement.scrollIntoView({ behavior: 'smooth', inline: 'center' })
        }
      })
      // this.activeIndex = index
      this.$emit('getTagId', {
        id,
        index
      })
    },
    getMore() {
      this.isShow = !this.isShow
    }
  }
}
</script>
<style lang="scss" scoped>
.tag {
  padding-top: 36px;
  padding-bottom: 12px;
  .tag-content {
    max-width: 1200px;
    overflow-x: hidden;
    .tag-item-box {
      width: calc(100% - 56px);
      overflow-x: auto;
      white-space: nowrap;
      &::-webkit-scrollbar {
        width: 6px; /* 设置滚动条宽度 */
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #fff; /* 滚动条轨道颜色 */
        position: relative;
      }
      &::-webkit-scrollbar-thumb {
        background: $robin-essential-color; /* 滚动条滑块颜色 */
        border-radius: 25px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: $robin-essential-color; /* 滚动条滑块hover颜色 */
      }
      .tag-item {
        color: #98aac0;
        margin-bottom: 20px;
        margin-right: 36px;
        cursor: pointer;
        position: relative;
        font-size: 20px;
        font-size: 500;
      }
      .tag-item-active {
        color: $robin-essential-color;
        position: relative;
      }
      .tag-item-active::after {
        position: absolute;
        content: '';
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 4px;
        background: $robin-essential-color;
        border-radius: 0px;
      }
    }
    .more {
      height: 24px;
      div {
        cursor: pointer;
        color: $robin-essential-color;
      }
      .img-active {
        transform: rotate(180deg);
      }
    }
  }
}
.tail-tag-content {
  @apply mx-auto;
}
.tail-flex {
  @apply flex;
}
@media (max-width: 1200px) {
  .tag {
    .tag-content {
      padding: 0 1rem;
      .tag-item-box {
        width: calc(100% - 56px);
        .tag-item {
          margin-right: 32px;
        }
      }
    }
  }
}
@media (max-width: 1146px) {
  .tag {
    .tag-content {
      .tag-item-box {
        .tag-item {
          margin-right: 25px;
        }
      }
    }
  }
}
// @media (max-width: 1067px) {
//   .tag {
//     .tag-content {
//       .tag-item-box {
//         .tag-item {
//             margin-right: 25px;
//         }
//       }
//     }
//   }
// }
@media (max-width: 767px) {
  .tag {
    padding-top: 13px;
    padding-bottom: 20px;
    .tag-content {
      .tag-item-box {
        width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
          display: none;
        }
        .tag-item {
          font-size: 14px;
          margin-bottom: 0px;
          margin-right: 14px;
          flex-shrink: 0;
        }
        .tag-item-active::after {
          position: absolute;
          content: '';
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 2px;
          background: $robin-essential-color;
          border-radius: 2px;
        }
      }

      .more {
        display: none;
      }
    }
  }
}
</style>
