import { getToken, getStoreToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    storeToken: getStoreToken(),
    permissions: [],
    userInfo: {
      name: '',
      avatar: ''
    },
    userType: '' // 用户登录类型 companyUser appUser
  },

  mutations: {
    set_token: (state, token) => {
      state.token = token
    },
    set_storeToken: (state, storeToken) => {
      state.storeToken = storeToken
    },
    set_permissions: (state, permissions) => {
      state.permissions = permissions
    },
    set_userInfo: (state, userInfo) => {
      state.userInfo = userInfo
    },
    set_userType: (state, userType) => {
      state.userType = userType
    }
  },

  actions: {
    SET_TOKEN({ commit }, token) {
      commit('set_token', token)
    },
    SET_STORETOKEN({ commit }, storeToken) {
      commit('set_storeToken', storeToken)
    },
    SET_PERMISSIONS({ commit }, permissions) {
      commit('set_permissions', permissions)
    },
    SET_USERINFO({ commit }, userInfo) {
      commit('set_userInfo', userInfo)
    },
    SET_USERTYPE({ commit }, userType) {
      commit('set_userType', userType)
    }
  }
}

export default user
